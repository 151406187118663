import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

import {
  ArticleDate,
} from "../../styledComponents/article"
import LayoutNoStripe from "../../components/layoutNoStripe"
import SEO from "../../components/seo"
import {
  Section,
  SectionHeading,
  SectionHeaderLedeDiv,
} from "../../styledComponents/section"
import { BackgroundColourBlock } from "../../styledComponents/overView"
import {
  StyledVideoTestimonialArticle,
  StyledVideoTestimonialHeader,
  StyledVideoTestimonialVideo,
  StyledVideoTestimonialLede
} from "../../styledComponents/videoTestimonial"
import EventList from "../../components/EventList"


const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const TestimonialClare = ({ data, location }) => {
  

  return (
    <LayoutNoStripe displayNavBorder location={location} hideNewsletterSignUp>
      <SEO 
      title="Quantum-Touch Testimonial from registered NHS nurse"
      description="Clare Green is a registered respiratory nurse working with patients in England’s NHS. She has been using the Quantum-Touch breath and techniques both personally and professionally."
      url="/quantum-touch/testimonial-clare-green-nhs-nurse"
      image="https://www.karinagrant.co.uk/images/clare-green-testimonial-placeholder.jpg"
      />
      <StyledVideoTestimonialArticle itemScope itemType="http://schema.org/Article">
        <StyledVideoTestimonialHeader>
          <h1 itemProp="headline">Quantum-Touch Testimonial</h1>
          <ArticleDate offScreen dateTime=''>Last updated on June 10th 2022</ArticleDate>
        </StyledVideoTestimonialHeader>

        <StyledVideoTestimonialVideo>
        <figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio"><div class="wp-block-embed__wrapper">
<iframe loading="lazy" title="Clare Green Quantum Touch testimonial" width="640" height="360" src="https://www.youtube.com/embed/McB69gAjgLE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
</div></figure>
        </StyledVideoTestimonialVideo>
        <StyledVideoTestimonialLede>Clare Green is a registered respiratory nurse working with patients in England’s NHS. She has been using the Quantum-Touch breath and techniques both personally and professionally.</StyledVideoTestimonialLede>
        <StyledVideoTestimonialLede>In this short video Clare talks about some of the highlights she has witnessed with Quantum-Touch. This includes blood pressure reduce, better breath output when doing “air flow tests” for the lungs and it is a part of her life as a nurse – especially through covid.</StyledVideoTestimonialLede>

      </StyledVideoTestimonialArticle>

      
    </LayoutNoStripe>
  )
}

export default TestimonialClare

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    wpPage(slug: { eq: "homepage" }) {
      title
      slug
      content
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }

    aboutKarina: wpPage(slug: { eq: "about" }) {
      aboutKarina {
        aboutKarina
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
      

    allWpPost(sort: { fields: [date], order: DESC } limit: 5) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        isSticky
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }


    allWpProduct(limit: 3) {
      edges {
        node {
          title
          uri
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            hidesale
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
}
`
